export const drumsamples = [
   {
      shortcut: "Q",
      sampleName: "snare1"
   },
   {
     shortcut: "W",
     sampleName: "snare2"
  },
  {
   shortcut: "E",
   sampleName: "kick1"
 },
 {
   shortcut: "A",
   sampleName: "kick2"
 },
 {
   shortcut: "S",
   sampleName: "hihat1"
 },
 {
   shortcut: "D",
   sampleName: "hihat2"
 },
 {
   shortcut: "Z",
   sampleName: "crash"
 },
 {
   shortcut: "X",
   sampleName: "clap"
 },
 {
   shortcut: "C",
   sampleName: "openhihat"
 },
 {
  shortcut: "F",
  sampleName: "perc1"
},
{
  shortcut: "G",
  sampleName: "perc2"
},
{
  shortcut: "H",
  sampleName: "ride"
},
{
  shortcut: "I",
  sampleName: "kick3"
},
{
  shortcut: "J",
  sampleName: "snare3"
},
{
  shortcut: "K",
  sampleName: "perc3"
},
{
  shortcut: "L",
  sampleName: "kick4"
}
 
 ]


