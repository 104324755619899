import React from "react"


function Drumpad(props) {

    return (
        
        <div className="drum-pad" id={props.className}  onClick={() => props.playHandler(props.shortCut, props.className)}>
           
             <audio id={props.shortCut}  className="clip" src={`https://markdownpreview.realvinylz.net/kit1/${props.className}.mp3`} />
        
            <p>{props.shortCut}</p>
        </div>
        
    )
}


export default Drumpad


