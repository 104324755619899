import React, {useState, useEffect} from 'react';
import Drumpad from "./components/Drumpad"
import Display from "./components/Display"
import './App.css';
import {drumsamples} from "../src/tools/drumsamples"


function App() {

  const [sampleName, setSampleName] = useState("initializing")
  
  function play(sample, name) {
    if (sample) {
     const audioEl = document.getElementById(sample)
     audioEl.play();
     setSampleName(name)
    }
    else return
  }


  function keyStrikeHandler(e) {
      let shortCutArray = [];
      let sampleId = String.fromCharCode(e.keyCode)
      drumsamples.map(item => shortCutArray.push(item.shortcut))
    
      if (shortCutArray.includes(sampleId)) {
        const audioEl = document.getElementById(sampleId)
        audioEl.play();
        let theDrumsample = drumsamples.filter(item => item.shortcut === sampleId)
        setSampleName(theDrumsample[0].sampleName)
      }
      else return
      
  }

 

  useEffect(() => {document.addEventListener('keydown', keyStrikeHandler);})

  return (
    <div className="main">
      <div className="header">
      <h1> Beat Dis</h1>
      { /* <i className="fas fa-drum"></i> */}
      </div>
        <div className="stage" id="drum-machine" >
       <Display name={sampleName}/>
          <div className="pads" >
            {drumsamples.map( item => (<Drumpad key={item.shortCut}  playHandler={play} shortCut={item.shortcut} className={item.sampleName}> </Drumpad>))}
          </div>
        
      </div>
    </div>
  );
}

export default App;
