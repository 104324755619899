import React from "react"

function Display(props) {

    return (
        <div id="display">
            <p>{props.name}</p>
           
        </div>
    )
}


export default Display